
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      listCategory: [],
      listStatus: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      url: ApiService.vueInstance.axios.defaults.baseURL,
      name: "",
      categoryId: "",
      urlimage1: "/assets/images/no_image.JPG",
      urlimagedepanshow: "/media/no-image/No_Image_Horizontal_TKI.png",
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    this.getData(this.paging.size, this.paging.page);
    setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    async getImage(img) {
      let image = "";
      let data = "";
      if (img) {
        await ApiService.getWithoutSlug(
          "crmv2/main_image/image/get/base64/" + img
        )
          .then((res) => {
            new Promise((resolve, reject) => resolve(res)).then(
              (value: any) => {
                image = value.data;
              }
            );
            // image = "data:image/jpg;base64," + res.data
          })
          .catch((e) => {
            return this.urlimage1;
          });
      } else {
        return this.urlimage1;
      }
      return image;
    },
    getData(size, page) {
      console.log("getdata");
      this.isLoading = true;
      let search = "";
      if (this.name) {
        search = "name=" + this.name + "&";
      } else {
        search;
      }
      ApiService.getWithData(
        `crmv2/order/product/admin/all?${search}page=${page}&limit=${size}&sort=createTime&dir=-1`,
        {}
      )
        .then(({ data }) => {
          console.log(data);
          this.isLoading = false;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          let content = data.content;
          this.tableData = content;

          content.map((prop, index) => {
            if (prop.imageProduct.image1) {
              setTimeout(() => {
                ApiService.getWithoutSlug(
                  "crmv2/main_image/image/get/base64/" +
                    prop.imageProduct.image1
                )
                  .then((res) => {
                    // console.log("res" + index, res.data);
                    content[index].mainImage =
                      "data:image/jpg;base64," + res.data;
                    // console.log(content, index);
                    this.tableData = content;
                    this.$forceUpdate();
                  })
                  .catch((e) => {
                    return this.urlimage1;
                  });
              }, 500);
            }
          });

          // ;
          // console.log(this.getImage("2022-12-08-tXtqtEal.jpeg"));
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    changeModal() {},

    dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
});
